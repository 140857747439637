@font-face {
    font-family: montserrat_thin;
    src: url('../fonts/montserrat_thin.ttf');
    src: url('../fonts/montserrat_thin.woff') format('woff'),
        url('../fonts/montserrat_thin.woff2') format('woff2');
}

@font-face {
    font-family: montserrat_light;
    src: url('../fonts/montserrat_light.ttf');
    src: url('../fonts/montserrat_light.woff') format('woff'),
        url('../fonts/montserrat_light.woff2') format('woff2');
}

@font-face {
    font-family: montserrat_regular;
    src: url('../fonts/montserrat_regular.ttf');
    src: url('../fonts/montserrat_regular.woff') format('woff'),
        url('../fonts/montserrat_regular.woff2') format('woff2');
}

@font-face {
    font-family: montserrat_medium;
    src: url('../fonts/Montserrat-Medium.ttf');
    src: url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.woff2') format('woff2');
}

@font-face {
    font-family: montserrat_semibold;
    src: url('../fonts/montserrat_semibold.ttf');
    src: url('../fonts/montserrat_semibold.woff') format('woff'),
        url('../fonts/montserrat_semibold.woff2') format('woff2');
}

@font-face {
    font-family: montserrat_bold;
    src: url('../fonts/montserrat_bold.ttf');
    src: url('../fonts/montserrat_bold.woff') format('woff'),
        url('../fonts/montserrat_bold.woff2') format('woff2');
}


@font-face {
    font-family: raleway_regular;
    src: url('../fonts/Raleway-Regular.woff');
}

@font-face {
    font-family: raleway_light;
    src: url('../fonts/Raleway-ExtraLight.woff');
}

@font-face {
    font-family: raleway_medium;
    src: url('../fonts/Raleway-Medium.ttf');
    src: url('../fonts/Raleway-Medium.woff') format('woff'),
        url('../fonts/Raleway-Medium.woff2') format('woff2');
}

@font-face {
    font-family: raleway_semiBold;
    src: url('../fonts/Raleway-SemiBold.woff');
}

@font-face {
    font-family: raleway_bold;
    src: url('../fonts/Raleway-Bold.ttf');
    src: url('../fonts/Raleway-Bold.woff') format('woff'),
        url('../fonts/Raleway-Bold.woff2') format('woff2');
}

@font-face {
    font-family: sf_pro_display;
    src: url('../fonts/SF-Pro-Display.OTF');
}

@font-face {
    font-family: sf_pro_display;
    src: url('../fonts/SF-Pro-Display.OTF');
}

@font-face {
    font-family: Roboto;
    src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: Gotham;
    src: url('../fonts/GothamBold.ttf');
    src: url('../fonts/GothamBold.woff') format('woff'),
        url('../fonts/GothamBold.woff2') format('woff2');
}

@font-face {
    font-family: Gotham;
    src: url('../fonts/GothamMedium.ttf');
    src: url('../fonts/GothamMedium.woff') format('woff'),
        url('../fonts/GothamMedium.woff2') format('woff2');
}

@font-face {
    font-family: Gotham_book;
    src: url('../fonts/Gotham-Book.ttf');
    src: url('../fonts/Gotham-Book.woff') format('woff'),
        url('../fonts/Gotham-Book.woff2') format('woff2');
}

.montserrat_thin {
    font-family: montserrat_thin;
    letter-spacing: .03rem;
}

.montserrat_medium {
    font-family: montserrat_medium;
    letter-spacing: .03rem;
}

.montserrat_light {
    font-family: montserrat_thin;
    letter-spacing: .03rem;
    font-weight: bold;
}

.montserrat_regular {
    font-family: montserrat_regular;
    letter-spacing: .03rem;
}

.montserrat_semibold {
    font-family: montserrat_semibold;
    letter-spacing: .03rem;
}

.montserrat_bold {
    font-family: montserrat_bold;
    letter-spacing: .03rem;
}



.raleway_medium {
    font-family: raleway_medium;
    letter-spacing: .03rem;
}

.raleway_bold {
    font-family: raleway_bold;
    letter-spacing: .03rem;
}

.sf_pro_display {
    font-family: sf_pro_display;
    letter-spacing: .03rem;
}