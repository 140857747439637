.modal {
    margin: 0 auto;
    padding: 20px 10px;
    transition: 0.5s;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    background: unset;
    max-height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: unset;

    .main_comman_modal {
        position: relative;
        background: #ffffff;
        box-shadow: 0px 0px 50px rgb(0 0 0 / 25%);
        border-radius: 20px;
        margin: auto;
        max-width: 956px;
        width: 100%;
        box-sizing: border-box;
        padding: 32px 20px 58px;
        top: 50%;
        transform: translate(0, -50%) !important;

        @media (max-height: 600px) {
            top: unset;
            transform: unset !important;
        }
    }

    .modal-content {
        display: block;
        border: none;
        position: unset;
    }

    .sub_appoinment_block {
        @include comman_scrollbar_firefox(#BFC6CF, transparent);

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: #BFC6CF;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }

    h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282C;
        @extend .montserrat_regular;
        margin-bottom: 40px;
    }

    .form_group.search .search_icon {
        left: 11px;
        top: 11px;
        transform: unset;
    }

    .close_modal {
        position: absolute;
        right: 12px;
        top: 15px;
        cursor: pointer;
    }

    .rc-time-picker-input {
        @extend .form_control;
    }

    @media (max-width: 600px) {
        max-height: unset !important;
        min-height: 100vh;
    }
}

.modal-backdrop {
    display: none;
}

.no_data_block {
    padding: 20px 10px;
    line-height: 2rem;
    color: #636363;
    font-family: "montserrat_medium";
    border-bottom: 1px solid #ddd;
    text-transform: unset;
}

.loader_chat {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 120px;
    align-items: center;
    left: 42%;
    background: #457b9d;
    padding: 5px;
    top: 5px;
    z-index: 99999;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    transition: all 0.3s ease 0s;

    img {
        width: 24px;
        height: 24px;
        display: block;
    }

    p {
        font-weight: 600;
        color: white;
        padding-left: 5px;
        text-transform: uppercase;
        font-size: 11px;
    }
}

.modal-dialog.deleteModal.main_comman_modal.change-min_modal {
    @include width(410px !important);
    border-radius: 10px;

    .custom_modal {
        padding: 0 !important;

        h3 {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000000;
        }

        p {
            font-family: montserrat_medium;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #495568;
            @include width(296px);
            margin: 24px auto 0;

            b {
                color: #000000;
            }
        }

        .form_action {
            margin-top: 26px;
        }
    }
}

// React DatePicker
//.react-datepicker-wrapper{
//  @extend .fullWidth;
//}
//.react-datepicker {
//  border: none;
//  @include boxShadow(0 0 2rem -.5rem rgba(#000, .4));
//  @extend .font_14;
//  @extend .poppins_500;
//
//  &__month-select,
//  &__year-select{
//    padding: 0.4rem 2rem;
//    border: 0;
//    line-height: 1.4rem;
//  }
//
//  .react-datepicker__day--today{
//    color: #000;
//    border-radius: 0.3rem;
//  }
//  .react-datepicker__time-container {
//    border-left: .1rem solid #F3F3F3;
//    .react-datepicker__time-list-item--selected,
//    .react-datepicker__time-list-item--keyboard-selected{
//      background-color: #FF1616;
//      color: #ffffff;
//    }
//  }
//  .react-datepicker__day--keyboard-selected {
//    background-color: #ffffff;
//    color: #000;
//  }
//  .react-datepicker__day--outside-month{
//    color: #9F9F9F;
//  }
//  .react-datepicker__today-button {
//    background-color: #ffffff;
//    border-top: .1rem solid #F3F3F3;
//  }
//  .react-datepicker__triangle {
//    display: none;
//  }
//  .react-datepicker__header {
//    background-color: #ffffff;
//    border-bottom: .1rem solid #F3F3F3;
//  }
//  .react-datepicker__day-name {
//    font-weight: 600;
//  }
//  .react-datepicker__current-month {
//    margin-bottom: 1rem;
//    @extend .font_16;
//  }
//  .react-datepicker__current-month,
//  .react-datepicker-time__header,
//  .react-datepicker-year-header {
//    color: #FF1616;
//  }
//  .react-datepicker__day-name,
//  .react-datepicker__day,
//  .react-datepicker__time-name {
//    width: 3rem;
//    line-height: 3rem;
//  }
//  .react-datepicker__navigation--next {
//    border-left-color: #FF1616;
//  }
//  .react-datepicker__navigation--previous {
//    border-right-color: #FF1616;
//  }
//  .react-datepicker__day--selected,
//  .react-datepicker__day--keyboard,
//  .react-datepicker__day--in-selecting-range,
//  .react-datepicker__day--in-range,
//  .react-datepicker__month-text--selected,
//  .react-datepicker__month-text--keyboard-selected,
//  .react-datepicker__month-text--in-selecting-range,
//  .react-datepicker__month-text--in-range,
//  .react-datepicker__quarter-text--selected,
//  .react-datepicker__quarter-text--keyboard-selected,
//  .react-datepicker__quarter-text--in-selecting-range,
//  .react-datepicker__quarter-text--in-range {
//    background-color: #FF1616;
//    &:hover {
//      background-color: #FF1616;
//    }
//  }
//  .react-datepicker__day--in-selecting-range,
//  .react-datepicker__month-text--in-selecting-range,
//  .react-datepicker__quarter-text--in-selecting-range {
//    background-color: rgba(#FF1616, 0.15);
//  }
//  .react-datepicker__month--selecting-range {
//    .react-datepicker__day--in-range,
//    .react-datepicker__month-text--in-range {
//      color: #ffffff;
//    }
//  }
//  .react-datepicker__quarter-text--in-range {
//    color: #ffffff;
//  }
//  .react-datepicker__day--selected.react-datepicker__day--today,
//  .react-datepicker__day--keyboard-selected.react-datepicker__day--today{
//    background-color: #FF1616;
//    color: #ffffff;
//  }
//
//  // Month Dropdown
//  .react-datepicker__month-dropdown,
//  .react-datepicker__year-dropdown{
//    background-color: #ffffff;
//    width: 60%;
//    left: 20%;
//    top: 3.7rem;
//    border-radius: 0.9rem;
//    border: 0;
//    @include boxShadow(0 0 2rem 0 rgba( #000, .2));
//  }
//
//  .react-datepicker__month-option,
//  .react-datepicker__year-option{
//    line-height: 2.2rem;
//    @include transition(all 0.3s ease-in-out);
//
//    &:hover{
//      color: #FF1616;
//      background: #ffffff;
//    }
//  }
//
//  .react-datepicker__month-option--selected_month,
//  .react-datepicker__year-option--selected_year,
//  .react-datepicker__month-option--keyboard-selected_month,
//  .react-datepicker__year-option--keyboard-selected_year{
//    color: #FF1616;
//    background: rgba( #FF1616, 0.2 );
//  }
//}
//.react-datepicker .react-datepicker__day--selected.react-datepicker__day--outside-month,
//.react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--outside-month
//{
//  color: #ffffff;
//}