.main_setting{
        margin: 10px;
        .setting_form{
           max-width: 460px;
           width: 100%;
           margin: 100px auto 0;
           padding: 30px;
           box-shadow: 0px 0px 20px rgb(214 214 214 / 50%);
           border-radius: 6px;
           .ant-form-item-label{
                padding-bottom: 4px;
           }
           label{
                display: block;
                font-weight: 600;
                color: #595959;
                @extend .montserrat_regular;
                margin-bottom: 8px;
           }
           input{
                padding: 6px 25px 6px 16px !important;
                background-color: unset;
           }
           span.ant-input-affix-wrapper{
                background: #F8F8F8;
                border: none !important;
                min-height: 4.2rem;
                width: 100%;
                border-radius: 1rem;
           }
           .ant-form-item-control-input-content {
                border: 1.5px solid #DEDEDE;
                border-radius: 1rem;
            }
            .setting_btn_block{
                    margin: 0 !important;
                    padding: 10px 0 0 0;
                    display: block;
                .ant-form-item-control-input-content{
                        border: none;
                        display: flex;
                        justify-content: center;
                        button.ant-btn.ant-btn-primary {
                                width: 49%;
                                line-height: 2rem;
                                transition: all 0.3s ease-in-out;
                                font-weight: 600;
                                @extend .montserrat_regular;
                                letter-spacing: 0.03rem;
                                font-size: 1.4rem;
                                height: unset !important;
                                border: none;
                                border-radius: 1rem;
                                padding: 1.1rem 2.5rem;
                               
                        }
                        .clear{
                                color: #868686;
                                background: #E8EAF0;
                                margin-right: 5px;
                        }
                        .submit{
                                background: #C00C2D;
                                color: #ffffff;
                                margin-left: 5px;
                        }
                        @media (max-width: 600px) {
                                &{
                                     flex-wrap: wrap;
                                     button.ant-btn.ant-btn-primary{
                                             width: 100%;
                                     }
                                     .clear, .submit{
                                             margin: 5px 0
                                     }
                                }
                        }
                }     
            }
           .ant-form-item{
                   margin-bottom: 16px;
           }
        }
        .form_group{
              position: relative; 
              margin-bottom: 16px;
            
        }
        .form_buttons{
                margin: 30px 0 0;
                @media (max-width: 600px) {
                        flex-wrap: wrap;
                        .btn{
                                min-width: unset;
                                width: 100%;
                                margin: 5px 0;
                        }
                }
        }
}

span.password_icon {
        position: absolute;
        right: 8px;
        top: 43px;
        display: block;
        cursor: pointer;
        @include width(17px);
        // opacity: 0.8;
        img{
                width: 100%;
                height: 100%;
                display: block;
        }
        &.icon_show{
            
                height: 15px;
        }
      
    }

    .admin_sigin{
        span.password_icon{
                &.icon_hide{
                        height: 15px;
                        top: 41px !important;
                }   
                &.icon_show{
                        top: 41px !important;
                } 
        }
        &.main_setting{
                span.password_icon{
                        &.icon_hide{
                                height: 16px;
                                top: 41px !important;
                        }
                }
        }
    }