.pms_patients_table{
        .react-datepicker__input-container, .react-datepicker-wrapper{
                position: relative;
                @extend .comman_width_block; 
                margin: 0 auto;
                input{
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                }
        }
        .main_datepicker_block{
                position: relative;
        }
        .cal_img_block{
                @extend .comman_width_block;
                // @include position(absolute);
                cursor: pointer;
                img{
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                }
                &.pending{
                        img{
                            opacity: 0.2;
                        }
                }
        }
}
.patients_list_main{
        &.clinics{
                height: auto;
        }
        .switch_box{
                max-width: unset;
                width: calc(100% - 330px);
                padding: 10px 10px 10px 30px;
                @media (max-width: 1200px) {
                        padding: 10px 5px 10px 10px;
                        max-width: 250px;
                        width: 100%;
                }
        }
        .heading_content {
                padding: 11px 52px 12px 48px;
                min-height: 75px;
                @media (max-width: 1024px) {
                        padding: 6px 20px;
                }
        }
}
.comman_width_block{
        max-width: 21px;
        width: 100%;
        height: 24px;
}
.sub_header_row{
        display: flex;
        max-width: 610px;
        width: 100%;
        align-items: center;
        .form_group{
                padding: 4px 20px 5px 0 !important;
                max-width: 330px;
                width: 100%;
                display: block;
                input.form_control {
                        min-width: unset !important;
                        max-width: unset !important;
                }
        }
        @media (max-width: 1200px) {
                &{
                  max-width: 100%;
                  justify-content: space-between;
                  .form_group{
                        max-width: 260px;
                        padding-right: 10px !important;
                  }
                }
        }
        @media (max-width: 768px) {
                &{
                  flex-direction: column;
                  align-items: flex-start;
                }
        }
}
.status_head{
        position: relative;
}
td.message_text{
        span{
           display: -webkit-box;
           -webkit-line-clamp: 3;
           -webkit-box-orient: vertical;
           overflow: hidden;
        }
}