.signin{
    @extend .position_relative;
    // .sign_banner{
    //     @extend .position_relative;
    //     img{
    //         width: 100%;
    //         // height: calc(100vh - 31rem);
    //         @extend .img_object_center;
    //     }
    // }
    .sigin_banner{
        height: calc(100vh - 140px);
        padding-bottom: 190px;
        position: relative;
        min-height: 960px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: calc(100% - 189px);
            background: url(../images/signin_banner.jpg) no-repeat;
            background-position: right top;
            background-size: cover;
            z-index: -1;
            @media (max-width: 1200px) {
                background-position: top;
            }
        }
        .btn_default{
            font-weight: 600;
        }
        .btn_default, .btn_primary {
            font-family: montserrat_regular !important;
            letter-spacing: 0.8px;
        }
    }
    .container{
        @include width(144rem);
        padding: 0 10px;
        @extend .mx_auto;
        .header_logo{
            @extend .position_absolute;
            top: 48px;
            max-width: 1330px;
            margin: 0 auto;
            left: 0;
            right: 0;
            padding: 0 30px;
        }
    }
    .sign_box{
        width: 100%;
        max-width: 55.4rem;
        overflow: hidden;
        box-shadow: 0px 0px 20px rgba(214, 214, 214, 0.5);
        margin: 0 auto -50px;
        .account_system{
            background-color: $dark_blue;   
            padding: 1.6rem 6.4rem;
            @media (max-width: 991px) {
                padding: 1.6rem 2.4rem;
            }
            h4{
                font-size: 20px;
                letter-spacing: 0.7px;
                text-transform: uppercase;         
                color: #FFFFFF;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .admin_sigin{
            padding: 3.3rem 20px;
            background: $white;
            @media (max-width: 991px) {
                padding: 30px;
            }
            .sub_admin-signin{
                @include width(437px);
                margin: 0 auto;
            }
            h2{
                margin-bottom: 56px;
                @media (max-width: 991px) {
                    margin-bottom: 30px;
                }
            }
            .form_group{
                margin-bottom: 14px;
                .form_control{
                    padding-right: 4rem;
                    &:focus{
                        background: #F8F8F8;
                        border: 1.5px solid #DEDEDE;
                    }
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover, 
                    &:-webkit-autofill:focus, 
                    &:-webkit-autofill:active{
                        -webkit-box-shadow: 0 0 0 30px #F8F8F8 inset !important;
                    }
                }
                ::placeholder{
                    color: $gray_place;
                }
                span{
                    @extend .position_absolute;
                    right: 1.5rem;
                    top: 38px;
                    @extend .cursor_pointer;
                }
            }
        }
        .btn_primary{
            min-width: 26.7rem;
        }
        input{
          font-weight: 400;
          font-family: montserrat_medium;
          color: #3E4A58 !important;
          background: #fff;
          line-height: 20px;
          &::placeholder{
            font-family: montserrat_medium;
            font-weight: 400;
          }
        }
        .form_buttons{
            margin: 43px 0 0;
            .btn_default{
                color: #868686 !important;
            }
            @media (max-width: 600px) {
                margin: 20px 0 !important;
                button{
                    min-width: unset;
                    width: 50%;
                }
            }
        }
    }
}
.signin_reset, .signin_submit {
    font-weight: 600;
    @extend .montserrat_regular;
}

.signup_block.sign_box .admin_sigin{
    padding: 32px 52px 44px;
    @media (max-width: 991px) {
        padding: 30px;
    }
    input{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.3125px;
        color: #3E4A58;
        font-family: montserrat_medium;
        font-weight: 500;
        &:focus{
            background: #E7E7E7 !important;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #E7E7E7 inset !important;
        }
    }
}
.sigin_banner.signup_banner_block{
    padding-bottom: 80px;
}
.forgot_password{
    display: block;
    background-color: unset;
    border: unset;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: montserrat_semibold;
    color: #595959;
    margin: 12px 0 0 auto;
}
.form_row {
    .verification_otp {
      margin-bottom: 26px;
      max-width: 240px;
      width: 100%;
      .styles_react-code-input-container__tpiKG{
        max-width: 240px !important;
      }
      .styles_react-code-input__CRulA{
        display: flex;
  
        input{
          align-items: center;
          background: #e5e5e5;
          border-radius: 5px;
          color: #000;
          display: flex;
          font-size: 40px;
          font-weight: 600;
          height: 58px;
          justify-content: space-between;
          line-height: 42px;
          margin: 4px;
          max-width: 40px;
          padding: 0;
          width: 100%;
          border: none;
          &::selection{
            background: unset;
            color: #000;
          }
          &:focus {
            outline: none;
            border: 2.5px solid #1784e9 !important;
            caret-color: #000;
          }
        }
      }
  
    }
}
.srv-validation-message {
    font-family: montserrat_semibold;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: #C00C2D !important;
    padding-top: 7px;
}

.forgot_psw_modal {
    .delete_content{
        @include width(324px);
        margin: 0 auto;
        padding-bottom: 0 !important;
    }
    h4 {
        margin: 0 !important;
    }
}
.video_content_wrapper{
    width: 100%;
}
.min-block, .resend_color, .resendcode_msg{
  display: inline-block;
}
.resend_color{
  color: #c7c9cc;
  cursor: pointer !important;
}
.min-block{
  padding-left: 5px;
}
.text_capitalize_md{
  text-transform: capitalize !important;
}