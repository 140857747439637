.psd_link_block{
        a{
            color: #3079EF;  
            font-family: "montserrat_medium",sans-serif;   
            font-weight: 500;
            @media (max-width: 680px) {
                max-width: 100% !important;
                text-align: left;
            }
        }
}
.update_patient_block {
    max-width: 690px;
    width: 100%;
    display: flex;
    padding: 38px 20px 20px 74px;
    .update_patient_profile{
        max-width: 120px;
        width: 100%;
        padding-left: 20px;
    }
    .update_patient_model{
        width: calc(100% - 120px);
        padding-right: 34px;
    }
    .form_action{
        margin-top: 36px;
        @media (max-width: 600px) {
            &{
                flex-direction: column;
                margin-top: 20px;
                .btn {
                    margin: 5px 0;
                }
            }
        }
    }
    .form_control{
        border: 2px solid #DEDEDE;
        height: 100%;
        min-height: unset;
        padding: 10px;
    }
    .form_row{
        @media (max-width: 768px) {
            &{
                flex-direction: column;
                .form_group {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
    .fill {
        min-height: 4.2rem;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    @media (max-width: 991px) {
        &{
            padding: 20px;
        }
    }
    @media (max-width: 768px) {
        &{
            flex-direction: column-reverse;
            .update_patient_profile{
                max-width: 100px;
                width: 100%;
                margin: 0 auto 20px;
                padding-left: 0;
            }
            .update_patient_model{
                width: 100%;
                padding-right: 0;
            }
        }
    }
}
.add_patient_block{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 5px 0 4px 10px;
    @include width(290px);
    @media (max-width: 1500px) {
        @include width(260px);
    }

    .patients_download{
        max-width: 24px;
        width: 100%;
        img{
            cursor: pointer;
        }
        a{
            display: block;
            img{
                width: 100%;
                max-width: 100%;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .btn{
        @include width(160px);
        margin-left: 5px;
        padding: 11px 20px !important;
    }
}
.patients_list_main{
    .link_block {
        @include width(210px);
        padding: 5px 0 4px 0;
      
        a{
            max-width: 100%;
        }
        @media (max-width: 1200px) {
            @include width(250px);
          a{
              text-align: left;
          }
        }
        @media (max-width: 768px) {
            @include width(100%);
        }
    }
}
.main_file_uploade{
    max-width: 42px;
    width: 100%;
    height: 42px;
    background: #495568;
    border-radius: 10px;
    padding: 9px;
    position: relative;
    margin-right: 5px;
    input[type="file"]{
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        max-width: 42px;
        width: 100%;
        height: 42px;
        @include position(absolute);
    }
    img{
        display: block;
        width: 100%;
        height: 100%;
    }
}
.link_block{
    padding: 5px 20px 4px 0;
    display: block;
    margin-left: auto;
    
    @media (max-width: 1300px) {
        margin-left: unset;
    }
    @media (max-width: 1024px) {
        padding-right: 20px;
    }
    &.blank_block{
        padding: 0;
    }
}
.add_comman_header{
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .psd_link_block{
        a{
            max-width: 210px;
        }
    }

}


// patients details 
.patients_details_block{
    .psd_contact_info{
        .physiciandetail_data_name{
            width: 100%;
            padding-right: 0 !important;
        }
    }
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow{
    display: none;
}
.cancel_changes_div {
    position: relative;
}
.schedulePatients_status.pending, .schedulePatients_status.request_chng{
    position: absolute;
    top: 0;
    right: 26px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    padding: 4px 12px;
    letter-spacing: 0.8px;
    background-color: #3E4A58;
    font-family: raleway_medium;
    font-weight: 500;
}
.schedulePatients_status.request_chng{
    background: #C00C2D;
}
.main_addpatient_block{
    display: flex;
    align-items: center;
    padding-left: 30px;
  
}