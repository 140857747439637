.hp_registration-sec {
        width: 100%;
        background: url(../images/resetpsw-bg.png) no-repeat;
        background-size: cover;
        padding: 64px 0 50px;
        min-height: calc(100vh - 54px);
        background-position-x: center;
        cursor: pointer;

        @media (max-width: 480px) {
                & {
                        min-height: calc(100vh - 69px);

                        &.hp_reg-successScreen {
                                min-height: 100vh;
                        }
                }
        }

        &.hp_reg-successScreen {
                min-height: 100vh;

                .container {
                        .hp_reg-logo {
                                margin-bottom: 57px;
                        }
                }
        }

        img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                display: block;
        }

        .container {
                margin: 0 auto;
                @include width(460px);
                padding: 0 20px;

                @media (max-width: 300px) {
                        padding: 0 15px;
                }

                .hp_reg-logo {
                        margin-bottom: 32px;

                        a {
                                display: block;

                                img {
                                        margin: 0 auto;
                                }
                        }
                }

                .hp_reg_form-Block {
                        margin: 0 auto;
                        @include width(300px);
                }

                .hp_reg_signup-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-left: 16px;
                        margin-bottom: 20px;

                        h2 {
                                font-family: raleway_semiBold;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 21px;
                                text-transform: capitalize;
                                color: #000000;
                                padding-right: 10px;
                        }

                }

                .hp_reg_inputField {
                        display: block;
                        margin-bottom: 18px;

                        .inner_inputField_block {
                                display: block;
                                width: 100%;
                                position: relative;
                        }

                        &:last-child {
                                margin-bottom: 0;
                        }

                        input {
                                width: 100%;
                                padding: 13px 15px 10px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15) !important;
                                border-radius: 5px;
                                font-family: raleway_semiBold;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 16px;
                                color: rgba(73, 85, 104, 0.8);
                                border: none;

                                &::placeholder {
                                        font-family: raleway_semiBold;
                                        font-weight: 600;
                                }
                        }

                        input.Password {
                                padding: 13px 34px 10px 15px;
                        }

                        .eye_icon {
                                position: absolute;
                                bottom: 9px;
                                display: block;
                                right: 12px;
                                cursor: pointer;
                                @include width(20px);
                                height: 17px;

                                img {
                                        width: 100%;
                                        height: 100%;
                                }
                        }



                        &.error_show {
                                margin-bottom: 11px;
                        }
                }

                .mob_hp_reg-buttons {
                        margin-top: 124px;
                        display: flex;
                        justify-content: flex-end;

                        .hp_reg-btn {
                                padding: 16.94px 10px 14.95px;
                                @include width(103px);
                                display: block;
                                background: #CBCBCB;
                                border-radius: 30px;
                                font-family: raleway_bold;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 16px;
                                text-align: center;
                                text-transform: uppercase;
                                color: #3E4A58;
                                border: none;

                                &.clear_btn {
                                        margin-right: 15px;
                                }

                                &.next_btn {
                                        background: #C00C2D;
                                        color: #fff;
                                }
                        }
                }

                .hp_successScreen_content {
                        @include width(302px);
                        margin: 0 auto;

                        @media (max-width: 375px) {
                                @include width(312px);
                        }

                        p {
                                font-family: raleway_semiBold;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 23px;
                                color: #000000;
                                margin-bottom: 57px;
                                display: block;
                        }

                        a {

                                font-family: raleway_bold;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 16px;
                                text-decoration-line: underline;
                                color: #C00C2D;
                                display: block;
                                margin-bottom: 40px;
                        }

                        .download_txt {
                                font-family: raleway_medium;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 16px;
                                color: #495568;
                                margin-bottom: 23px;
                        }

                        .link_btns {
                                display: flex;
                                justify-content: flex-start;

                                .appStore {
                                        margin-right: 15px;
                                }

                                .appStore,
                                .googlePlay {
                                        display: block;
                                        margin-bottom: 0;
                                }
                        }
                }
        }
}

.hp_reg-error .srv-validation-message,
.hp_reg_mob-error {
        display: block;
        font-family: raleway_medium;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        color: #F84747 !important;
        padding-top: 8px;

        &:empty {
                padding: 0;
        }
}


.mobile_reg-footer {
        background: #457B9D;
        padding: 19px 20px 20px;

        p {
                font-family: raleway_light;
                font-weight: 300;
                font-size: 11px;
                line-height: 15px;
                text-align: center;
                letter-spacing: 0.5px;
                color: #ECE8DE;

                a {
                        display: inline-block;
                        font-family: raleway_bold;
                        font-weight: 700;
                        color: #fff;
                }

                @media (max-width: 480px) {
                        & {
                                max-width: 202px;
                                width: 100%;
                                margin: 0 auto;
                        }
                }
        }
}

.mob_pr_modal {
        padding: 20px 10px;
        transform: scale(1);
        transition: 0.5s;
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        align-items: center;

        .pr_modal_container {
                @include width(285px);
                margin: 0 auto;
                background: #FFFFFF;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                padding: 31px 10px 34px;

                h4 {
                        font-family: raleway_semiBold;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #000000;
                        display: block;
                        text-transform: capitalize;
                        margin-bottom: 20px;
                }

                p {
                        font-family: raleway_regular;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #000000;
                        @include width(238px);
                        margin: 0 auto 26px;
                }

                .ok_btn {
                        background: #457B9D;
                        border-radius: 30px;
                        @include width(100px);
                        margin: 0 auto;
                        padding: 12px 10px;
                        font-family: raleway_bold;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #FFFFFF;
                        border: none;
                        display: block;
                }
        }

}

.add_signin_link {
        margin-top: 25px;
        text-align: right;
        font-family: raleway_medium;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #3E4A58;
        text-transform: capitalize;

        a {
                display: inline-block;
                color: #000000;
                font-family: raleway_semiBold;
                font-weight: 600;
        }
}

.modal-dialog.main_comman_modal.authenticate_modal.mob_authenticate_model {
        max-width: 280px;
        padding: 23px 18px 26px;

        .custom_modal {
                h3 {
                        font-family: raleway_semiBold;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        text-transform: capitalize;
                        color: #000000;
                        margin-bottom: 18px;
                }

                .otpMsg {
                        .otp_error-msg {
                                padding: 0 0 12px !important;
                        }

                        .notice {
                                font-family: raleway_regular;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 15px;
                                text-align: center;
                                color: #495568;
                                padding: 0;
                                margin: 0 auto 22px;
                        }

                        p.resendcode_msg,
                        .resend_color {
                                font-family: raleway_bold;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: #C00C2D;
                        }

                        .resend_color {
                                color: #c7c9cc !important;
                                font-family: raleway_semiBold;
                                font-weight: 600;
                        }

                        .form_row .verification_otp .styles_react-code-input-container__tpiKG {
                                width: 100% !important;
                                max-width: 250px !important;
                        }

                        .form_row .verification_otp {
                                max-width: unset !important;
                                margin-bottom: 20px;
                        }

                        .form_row .verification_otp {
                                .styles_react-code-input__CRulA input {
                                        height: 58px !important;
                                        max-width: 41px;
                                        font-family: raleway_semiBold !important;
                                        font-weight: 600;
                                        text-transform: capitalize;
                                        color: #000000;
                                        display: inline-block !important;
                                        line-height: 58px;
                                        vertical-align: middle;
                                }
                        }

                        .mob_hp_reg-buttons {
                                justify-content: center;
                                margin: 24px 0 34px;
                        }
                }
        }
}

.mob_hp_reg-buttons {
        margin-top: 124px;
        display: flex;
        justify-content: flex-end;

        .hp_reg-btn {
                padding: 16.94px 10px 14.95px;
                @include width(103px);
                display: block;
                background: #CBCBCB;
                border-radius: 30px;
                font-family: raleway_bold;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                color: #3E4A58;
                border: none;

                &.clear_btn {
                        margin-right: 15px;
                }

                &.next_btn {
                        background: #C00C2D;
                        color: #fff;
                }
        }
}