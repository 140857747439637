
.resetpssword_main{
    width: 100%;
    height: 100vh;
    background: url(../images/resetpsw-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 20px 30px;
    @media (max-height: 500px) {
        height: 100%;
    }
        .resetpsw_sub_block{
            @include width(600px);
            margin: 0 auto;
            @media (max-width: 991px) {
                @include width(300px);
            }
            .header{
                padding-bottom: 77px;
                img{
                    display: block;
                    max-width: 307px;
                    width: auto;
                }
            }
            .resetpsw_form_block{
                h4{
                    font-family: raleway_bold;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #000000;
                    text-align: left;
                    text-transform: capitalize;
                    padding: 0 0 24px 15px
                }
                .resetpsw_form{
                    @include width(300px);
                    .form_group {
                        background: #FFFFFF;
                        box-shadow: 0px 0px 25px rgb(0 0 0 / 15%) !important;
                        border-radius: 5px;
                        border: none;
                        padding: 9px 20px 9px 15px;
                        position: relative;
                        margin-bottom: 0;
                        input{
                            font-family: raleway_bold;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 16px;
                            //text-transform: capitalize;
                            color: rgba(73, 85, 104, 0.8);
                            padding: 0;
                            border: none;
                            min-height: unset;
                            background: unset;
                            border-radius: 0px;
                            &::placeholder{
                                font-family: raleway_medium;
                                color: rgba(73, 85, 104, 0.6);
                        }
                    }
                    .password_icon {
                        top: 56%;
                        transform: translate(0, -50%);
                    }
                    &:hover, &:focus{
                        box-shadow: 0px 0px 25px rgb(0 0 0 / 15%) !important;
                    }
                }
                .srv-validation-message{
                    padding: 8px 0 5px 15px;
                }
                .form_group:not(:first-child){
                    margin: 25px 0 0;
                }
                .form_buttons{
                    display: flex;
                    justify-content: flex-end;
                    margin: 45px 0 0;
                    button.btn {
                        min-width: unset;
                        max-width: 105px;
                        width: 100%;
                        border-radius: 30px;
                        padding: 14px 10px 13px;
                        text-transform: uppercase;
                        font-family: raleway_medium;
                        font-weight: 600;
                        letter-spacing: 0.8px;
                        &.btn_default{
                            background: #CBCBCB;
                            color: #3E4A58;
                        }
                    }
                }
            }
            .successfully_msg{
                font-family: raleway_bold;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: rgba(73, 85, 104, 0.8);
                @include width(450px);
                padding: 0 0 20px;
            }
            }
        }

}