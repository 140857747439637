.billing_details_main{
    max-width: 1390px;
    width: 100%;
    padding: 6px 20px 30px 90px;
    @media (max-width: 1680px){
      &{
        max-width: 1235px;
      }
    }
    @media (max-width: 1400px){
      &{
        padding: 6px 20px 30px 40px;
      }
    }
    @media (max-width: 1200px){
      &{
        padding: 6px 20px 30px 20px;
      }
    }
    @media (max-width: 991px){
      &{
        padding: 20px 20px 30px 20px;
      }
    }
}
.forms_details{
    margin-bottom: 2px;
    @extend .montserrat_regular ;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: rgba(89, 89, 89, 0.5);
}

.clinic_detail{
    width:calc( 100% - 250px );
       .clinic_detail_heading{
      //  background: #9AB0BD;
       background: rgba(154, 176, 189, 0.19);
          .clinic_detail_heading_box {
            @extend .d_flex;
            @extend .justify_content_between;
            flex-wrap: wrap;
            max-width: 1390px;
            width: 100%;     
            padding: 27px 20px 28px 80px;
            @media (max-width: 1680px){
                &{
                  max-width: 1235px;
                }
            }

                  .clinic_detail_heading_text h2{
                    @extend .montserrat_regular;    
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.3125px;
                    text-transform: uppercase;
                    color: $black;
                    margin: 0;
                  }
                  @media (max-width: 991px){
                    &{
                      padding: 20px;
                      h2{
                        padding: 2px;
                      }
                    }
                  }
          }
      }


          .clinic_details_flex{
            max-width: 1390px;
            width: 100%;
            padding: 30px 20px 10px 90px;
            @media (max-width: 1680px){
              &{
                max-width: 1235px;
              }
            }
            @media (max-width: 1400px){
              &{
                padding: 30px 20px 10px 40px;
              }
            }
            @media (max-width: 1200px){
              &{
                padding: 30px 20px 10px 20px;
              }
            }
            @media (max-width: 991px){
              &{
                flex-direction: column;
              }
            }
            @extend .d_flex;
                  .clinic_details_flex--block1{
                    width: 50%;
                    padding-right: 31px;
                    @media (max-width: 1400px){
                      &{
                         padding-right: 20px;
                      }
                    }
                    @media (max-width: 991px){
                      &{
                        width: 100%;
                        padding: 0;
                      }
                    }
                       #form{
                         padding-left: 27px;
                         .forms_details{
                           @extend .d_flex;
                          justify-content: space-between;
                        }
                         .clinic_name_wrap{
                          @extend .d_flex;
                            .clinic_data {
                              width: 100%;
                              &:first-child{
                                padding-right: 10px;
                              }
                              &:nth-child(2){
                                padding-left: 10px;
                              }
                              .forms_details{
                                display: block;
                                width: 100%;
                              }
                            .forms_details_text{
                              width: 100%;
                              margin:12px 28px 27px 0;
                              @extend .montserrat_regular;
                              font-style: normal;
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 20px;
                              letter-spacing: 0.3125px;
                              color: $gray_label;
                             }
                         }
                        }
                          // .forms_details{
                          //   justify-content: space-between;
                          //   // .forms_details_text{
                          //   //   border: 2px solid red;
                          //   // }
                          // }
                          .edit{
                            @extend .montserrat_semibold;
                            @extend .text_center;
                            background: $maroon;
                            color: $white;
                            padding: 3px 17px 3px 19px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 20px;                                                   
                            // text-align: center;
                            border-radius: 3px;
                            border: none;
                        }
                          #clinic_name{
                             margin: 5px 0 30px;
                            
                            @extend .montserrat_semibold;
                            color: $gray_label;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;                           
                            letter-spacing: 0.3125px;
                            border: none    ;
                            // width: 452px;
                                 ::placeholder{
                                   color:$gray_label;
                                }
                        }

                    

                      .forms_details_box{
                        margin: 6px 0 32px;
                          .clinicname_wrap{
                            @extend .d_flex;
                          }
                            .clinicname_wrap_data{
                              width: 50%;
                              .forms_details{
                                margin:5px 0 13px;
                              }
                              .forms_details_text{    
                                margin-bottom: 22px;
                                @extend .montserrat_regular;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;                                                            
                                letter-spacing: 0.3125px;
                                color: #595959;
                                }
                            
                          }
                      }

                      input,.primarycontact h5{
                        @extend .montserrat_semibold;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;                                            
                        letter-spacing: 0.3125px;
                        color: $gray_label;
                        border: none;
                        margin: 10px 0 29px 0;
                        width: 100%;
                    }
                    .primarycontact h5{
                      margin: 0;
                    }
                }

                  .business_box_heading h5{
                    display: inline;
                    padding-left: 27px;
                    @extend .montserrat_regular;
                    margin: 18px 0 7px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;                    
                    letter-spacing: 0.3125px;
                    color: #595959;
                  }

                  .business_box{
                      .business_address_box{
                            margin: 10px 0 16px;
                            padding: 18px 36px 38px;
                            background: #F4F4F4;
                            @media (max-width: 1200px){
                              &{
                                padding: 20px;
                              }
                            }

                            .business_data{
                              &.clinic_data{
                                width: 100%;
                                padding-bottom: 32px;
                              }
                              p#street{
                                margin-bottom: 0 !important;
                              }
                              &.business_addr{
                                padding-bottom: 25px;
                              }
                            }
                            .business_addr{
                              width: 100%;
                              max-width: 173px;
                              margin: 0;
                              &:first-child{
                                padding-right: 10px;
                              }
                              &:nth-child(2){
                                padding-left: 10px;
                              }
                            }
                            .state_street, .country{
                              margin: 0;
                              p{
                                margin: 5px 0 18px !important;
                              }
                            }
                            .country{
                              padding-right: 10px;
                              width: 60%;
                            }
                            .state_street{
                              width: 40%;
                              padding-left: 10px;
                            }
                                      .businessforms_details{
                                        margin-bottom: 7px;
                                        @extend .montserrat_regular;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 13.5px;
                                        line-height: 16px;
                                        color: rgba(89, 89, 89, 0.5);
                                        }
                                              #street{
                                                margin:5px 0 20px;
                                                @extend .montserrat_regular;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 20px;
                                                letter-spacing: 0.3125px;
                                                color: #595959;
                                                @media (max-width: 1200px){
                                                  &{
                                                    font-size: 14px;
                                                  }
                                                }
                                              }
                                              #city,#zipcode{
                                                margin:12px 0 22px;
                                                @extend .montserrat_regular;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 20px;
                                                letter-spacing: 0.3125px;
                                                color: #595959;
                                            }
                                              #country,#state{
                                                margin:12px 0 22px;
                                            }
                                              input{
                                                background: transparent;
                                                width: 100%;
                                                @extend .montserrat_semibold;
                                                color: $gray_label;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 20px;
                                                letter-spacing: 0.3125px;
                                                border: none;

                                        }
                      }
                      &:last-child {
                        .business_address_box{
                          margin-bottom: 0;
                        }
                      }
                      }
                            }

                              .clinic_details_flex--block2{
                                width: 50%;
                                 padding-left: 31px;
                                 @media (max-width: 1400px){
                                   &{
                                      padding-left: 20px;
                                   }
                                 }
                                 @media (max-width: 991px){
                                  &{
                                    width: 100%;
                                    padding: 20px 0 0 0;
                                  }
                                }
                                   .authorized_users{
                                    //  height: 360px;
                                     background: #EEEEEE;
                                     .authorized_users_head{
                                        @extend .d_flex;
                                        @extend .align_items_center;
                                        @extend .justify_content_center;
                                        // width: 100%;
                                        justify-content: flex-end;
                                        padding: 7px 20px;
                                        background: #9BA1AB;
                                        border-radius: 5px 5px 0 0;
                                        background: #9BA1AB;
                                              .authorized_users_head_heading{
                                                width: 100%;
                                              h4{ 
                                                @extend .montserrat_semibold;
                                                @extend .text_center;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 14px;
                                                line-height: 20px;                                                                                            
                                                 letter-spacing: 0.3125px;
                                                color: $white;
                                                margin: 0;
                                            }
                                          }
                                              .add{
                                                // margin-left: 9px;
                                                // padding: 3px 7px 3px 18px;
                                                padding: 3px 18px;
                                                // width: 61px;
                                                @extend .montserrat_semibold;
                                                @extend .text_center;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 12px;
                                                line-height: 20px;
                                                color: rgba(0, 0, 0, 0.5);
                                                border-radius: 3px;
                                                border: none;
                                                background: $white;

                                            }
                                              .add_grey{
                                                background: rgba(251, 251, 251, 0.5);
                                            }
                                            .authorized_users_head_heading{
                                              width: calc(100% - 65px);
                                            }
                                            .add_main {
                                              max-width: 64px;
                                              width: 100%;
                                          }
                                        }

                                        .main_physicians_users_table{
                                          overflow: auto;
                                          height: 445px;
                                        }
                                        .main_authorized_table {
                                          overflow: auto;
                                          height: 320px;
                                        }
                                          .authorized_users_table{
                                              width: 100%;
                                              border-collapse: collapse;

                                              td {
                                                padding: 10px 5px;
                                              }
                                              // tbody tr, thead {
                                              //   display: table;
                                              //   width: 100%;
                                              // }
                                              // tbody{
                                              //   height: 280px;
                                              //   overflow: auto;
                                              //   display: block;
                                              //   width: 100%;
                                              // }

                                              .primarycontactheading{
                                                width: 10%;
                                                @extend .montserrat_regular;
                                                padding: 8px 0;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 10px;
                                                line-height: 20px;
                                                letter-spacing: 0.3125px;
                                                text-transform: capitalize;
                                                color: #495668;
                                                  }
                                                  .blank{
                                                    width: 0%;
                                                  }
                                                .addon{
                                                  width: 26%;
                                                  @extend .primarycontactheading;
                                                }
                                                .action_heading{  
                                                  width: 24%;
                                                  text-align: right;
                                                  padding: 8px 20px;
                                                  @extend .primarycontactheading;

                                                }
                                            }
                          
                                              .authorized_users_data{
                                                background: #DADADA;
                                              }
                                              .authorized_users_data:nth-child(even){
                                                background: #F8F8F8;
                                              }

                                              .authorized_users_data_info{
                                                list-style: none;
                                                .authorized_users_name h4{
                                                  text-align: left;
                                                  font-family: montserrat_medium;
                                                  font-weight: 600 !important;
                                                  font-size: 12px;
                                                  line-height: 20px;
                                                  letter-spacing: 0.3125px;
                                                  color: #2D2D2D;
                                                  margin: 0;
                                                }
                                                .authorized_users_email h5,.authorized_users_contact h5{
                                                  @extend .montserrat_regular;
                                                  font-style: normal;
                                                  font-weight: 600;
                                                  font-size: 12px;
                                                  line-height: 20px;
                                                  letter-spacing: 0.3125px;
                                                  color: #636363;
                                                }
                                              }
                                              .alert{
                                                padding: 8px !important;
                                              }

                                              .addon_date{
                                                vertical-align: baseline;
                                                text-align: center;
                                                @extend .montserrat_semibold;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 12px;
                                                line-height: 20px;                                                                                              
                                                letter-spacing: 0.3125px;
                                                color: #2D2D2D;
                                              }
                                              .td_checkbox, .td_icons{
                                                vertical-align: baseline;
                                              }
                                              .authorized_users_checkbox{
                                                margin: auto  ;
                                                @extend .justify_content_center;
                                              }

                                              .authorized_users_data_img{
                                                @extend .d_flex;
                                                @extend .justify_content_end;
                                                padding-right: 12px;
                                                  .user_option{
                                                  cursor: pointer;
                                                  margin-left:7px;
                                                }
                                              }
                                              .invite_div{
                                                text-align: right;
                                                padding-right: 12px;
                                              .invite{
                                                padding: 5px 11px;
                                                margin-top: 14px;
                                                 @extend .montserrat_semibold;
                                                color: $white;     
                                                min-width: 100px;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 10px;
                                                line-height: 20px;
                                                text-align: center;
                                          }
                                        }
                                        
                                        }
                                        .physicians{ 
                                          margin-top: 42px;
                                          @extend .authorized_users;
                                        .physicians_users_head{
                                          background: $dark_blue;
                                          @extend .authorized_users_head;
                                          @extend .d_flex;
                                          @extend .justify_content_center;
                                          @extend .align_items_center;
                                          .authorized_users_head_heading{
                                            width: 100%;
 
                                          }
                                        }
                                        @media (max-width: 991px){
                                          margin-top: 20px;
                                        }
                                      }
                                      .physicians_addon{
                                        @extend .addon;
                                      }                                      
                                      .physicians_action_heading{ 
                                        @extend .action_heading;
                                          padding:5px 20px; 
                                         width: 23%; 
                                         text-align: right; 
                                        }


                                      }}
                                        }

                                      .billing_heading h4{
                                        @extend .montserrat_regular;
                                        @extend .text_left;
                                        margin: 0 0 12px 30px;
                                        // margin-bottom: 15px;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 20px;                                        
                                        letter-spacing: 0.3125px;
                                        color: #595959;
                                        text-transform: capitalize;
                                    }
                                      .billing_details{
                                        background: #F4F4F4;
                                          .curr_bill h4{
                                            padding:20px 25px 10px;
                                            @extend .montserrat_bold;
                                            @extend .text_left;
                                            font-style: normal;
                                            font-weight: bold;
                                            font-size: 14px;
                                            line-height: 20px;
                                            letter-spacing: 0.3125px;
                                            color: $dark_blue;
                                            margin: 0;
                                        }
                                          .curr_bill_details{
                                            margin: 0 16px;
                                            background: #E5E8EB;
                                            border-radius: 3px;
                                            padding:   11px 9px 13px;
                                        
                                          .curr_bill_details_text{
                                            @extend .d_flex;
                                            flex-wrap: wrap;
                                            .curr_bill{
                                              width: 33%;
                                              @media (max-width: 1200px){
                                                &{
                                                  width: 50%;
                                                }
                                              }
                                              @media (max-width: 991px){
                                                &{
                                                  width: 100%;
                                                }
                                              }
                                            }
                                            .curr_bill_details_text1
                                              h6{
                                                // width:256px;
                                                @extend .montserrat_regular;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 12px;
                                                line-height: 20px;
                                                letter-spacing: 0.3125px;
                                                color: $gray_label;
                                                margin-bottom: 3px;
                                                margin-right: 32px;
                                                
                                              }
                                                .curr_bill {
                                                  h6{
                                                  @extend .montserrat_regular;
                                                  text-align: left;
                                                  font-style: normal;
                                                  font-weight: 600;
                                                  font-size: 12px;
                                                  line-height: 20px;                                                                                                  
                                                  letter-spacing: 0.3125px;
                                                  color: $gray_label;
                                                }
                                              }
                                              }
                                          .projectedcost h6{
                                            margin: 15px 0 0 0;
                                            @extend .montserrat_regular;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 12px;
                                            line-height: 20px;                                                                                                    
                                            letter-spacing: 0.3125px;
                                            color: #595959;
                                        }
                                    }

                                    .pastinvoice_main{
                                      display: flex;
                                      padding: 30px 0 80px 0;
                                      .past_invoices{
                                        max-width: 240px;
                                        width: 100%;
                                        .invoices_date{
                                          padding: 12px 10px 0 25px;
                                          span{
                                            display: block;
                                            @extend .montserrat_regular;
                                            font-weight: 600;
                                            font-size: 12px;
                                            line-height: 20px;
                                            letter-spacing: 0.3125px;
                                            color: #000000;
                                            &:not(:last-child){
                                              padding-bottom: 15px;
                                            }
                                          }
                                        }
                                      }
                                      .total_charged{
                                        width: calc(100% - 240px);
                                        .charges{
                                          max-width: 300px;
                                          width: 100%;
                                          padding: 12px 10px 0 25px;
                                          .charges_row{
                                            display: flex;
                                            justify-content: space-between;
                                            &:not(:last-child){
                                              padding-bottom: 15px;
                                            }
                                            span, .viewinvoice{
                                              display: block;
                                              @extend .montserrat_regular;
                                              font-weight: 600;
                                              font-size: 12px;
                                              line-height: 20px;
                                              letter-spacing: 0.3125px;
                                              color: #000000;
                                            }
                                            .viewinvoice{
                                              text-decoration-line: underline;
                                              color: #3079EF;
                                              font-weight: 500;
                                            }
                                          }
                                        }
                                      }
                                      h6{
                                        padding: 0 10px 8px 25px;
                                        border-bottom: 1px solid #C9C9C9;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 20px;
                                        letter-spacing: 0.3125px;
                                        color: #457B9D;
                                        margin: 0;
                                      }
                                      @media (max-width: 768px){
                                        &{
                                          flex-direction: column;
                                          padding: 30px 0;
                                          .past_invoices{
                                            max-width: 100%;
                                            .invoices_date{
                                              padding-bottom: 20px;
                                            }
                                          }
                                          .total_charged{
                                            width: 100%;
                                            .charges{
                                              max-width: 100%;
                                            }
                                          }
                                        }
                                      }
                                    }
                         
                              }                                    
                              .primarycontact_box{
                                //padding: 31px 77px 43px;
                                text-align: center;
                                //margin-top: 51px;
                                //max-width: 523px;
                                //background: #FFFFFF;
                                //box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
                                //border-radius: 10px;
                                .primarycontact_heading h5{
                                   @extend .montserrat_regular;
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: 14px;
                                  line-height: 17px;
                                  text-align: center;
                                  text-transform: capitalize;
                                  color: #000000;
                                }
                                .primarycontact_subheading p{
                                  margin: 27px 0 29px;
                                  @extend .montserrat_regular;
                                  font-style: normal;
                                  font-weight: 500;
                                  font-size: 12px;
                                  line-height: 15px;                                                                 
                                  text-align: center;
                                  color: #495568;
                                }
                                .primarycontact_text h6{
                                  margin-bottom: 23px;
                                  @extend .montserrat_regular;
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: 12px;
                                  line-height: 15px;                                                                  
                                  text-align: center;
                                  color: #000000;
                                }
                                #password{
                                  padding: 11px 10px;
                                  width: 328px;
                                  background: #F8F8F8;
                                  border: 1.5px solid #DEDEDE;
                                  box-sizing: border-box;
                                  border-radius: 10px;
                                }
                                #icon_hide_password{
                                  margin-left: -30px;
                                  cursor: pointer;
                                }
                                .warning{
                                   text-align: center;
                                  margin: 10px auto 24px;
                                  @extend .montserrat_regular;
                                  font-style: normal;
                                  font-weight: 500;
                                  font-size: 10px;
                                  line-height: 12px;
                                   align-items: center;
                                  text-align: center;
                                  letter-spacing: -0.288px;
                                  color: #C00C2D;
                                  // @include media575{
                                  //   color: #000;
                                  // }
                                }
                                .buttons{
                                  @extend .d_flex;
                                }
                              }
.authorized_users , .physicians{
  th{
      position: sticky;
      top: 0;
      background-color: #eeeeee;
      z-index: 99;
  }
}
 
                            


