.pagination-container {
  background-color: white;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  border-radius: 30px;
  padding: 8px 14px;
  margin: 20px auto;
  display: table;
  @media (max-width: 991px) {
    &{
      padding: 8px 10px;
    }
  }

  .pagination-item {
    height: 20px;
    text-align: center;
    margin: auto 1px;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    box-sizing: border-box;
    border-radius: 16px;
    line-height: 20px;
    font-size: 12px;
    min-width: 20px;
    position: relative;
    vertical-align: middle;
    font-family: "SF_Pro", sans-serif;
    font-weight: 600;
    @media (max-width: 991px) {
      &{
        margin: auto 2px;
      }
    }

    &:first-child, &:last-child{
      margin: 0px;
      padding: 0;
      min-width: 16px;
      &:hover, &.selected{
        background-color: unset;
      }
    }


    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: #52BCE9;
      cursor: pointer;
      color: #fff;
    }

    &.selected {
      background-color: #52BCE9;
      color: #fff;
    }

    .arrow {
      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.left::before { 
        background-image: url(../images/fe_arrow-left.png);
        left: -7px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.right::before {
        background-image: url(../images/fe_arrow-right.png);
        right: -7px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
