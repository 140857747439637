.ReactModal__Overlay--after-open {
  background-color: transparent !important;
}


.ant-modal{
  padding-bottom: 0px !important;
  .ant-modal-content{
    border-radius: 15px;
  }
}
.modal_dialog {
  border-radius: 1rem !important;
}

//.ant-modal-mask{
//  background: transparent !important;
//}
.ant-modal-close-x{
  color: black;
}

.modal {
  max-height: 90vh;
  padding-top: 3.7rem;
  padding-bottom: 3.7rem;
  margin: 0 auto;
  margin-top: 5vh;
  position: relative;
  background: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.13);
  &.addPhy{
    max-width: 956px;
    width: 100% !important;
  }

  & > button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background-image: url(./../images/icon_cross.svg);
    background-size: 1.2rem 1.2rem;
    background-repeat:  no-repeat;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0;
  }
    .custom_modal {
      padding: 0 10px 20px 10px;
      overflow-y: auto;
      max-height: 80vh;
      @include scrollbar;
      @media (max-width: 768px){
        padding: 0;
      }
      .form {
        display: flex;
        max-width: 674px;
        width: 100%;
        margin: 0 auto;
        .form_heading {
          max-width: 445px;
          width: 100%;
        }
        .form_group.profile {
          max-width: 120px;
          width: 100%;
          padding: 0 20px 0 0;
        }
        .physician_form {
          width: calc(100% - 120px);
          padding-left: 20px;
          .form_action{
            margin-top: 46px;
          }
          .form_group {
            margin-bottom: 8px;
            label {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              color: #595959;
            }
          }
        }
        .form_row, .form_group {
          max-width: 434px;
          width: 100%;
      }
        @media (max-width: 768px){
          &{
            .form_group.profile, .authorized_profile {
              margin: 0 auto !important;
              padding: 0 10px 20px !important;
          }
          }
        }
        @media (max-width: 580px){
          &{
            flex-direction: column;
            .form_group, .form_row{
              max-width: 100%;
            }
            .physician_form, .authorized_form_model{
              width: 100% !important;
              padding-left: 0 !important;
            }
          }
        }
      }
      .authorized_form{
        max-width: 700px;
        .user_profile_pic{
          text-align: center;
          margin-bottom: 10px;
          .addnew{
            right: 6px;
          }
        }
        .authorized_profile{
          max-width: 140px;
          padding-right: 25px;
          padding: 0 25px 0 0;
        }
        .authorized_form_model {
          width: calc(100% - 140px);
          padding-left: 25px;
        }
        .user_profile{
          label {
            text-align: center;
          }
        }
        @media (max-width: 768px){
          &{
            .authorized_form_model{
              h3{
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .user_profile {
      &_pic {
        position: relative;
        & > img {
          height: 100px;
          width: 100px;
          border-radius: 100%;
          object-fit: cover;
        }
       

        .addnew {
          height: 2.6rem;
          width: 2.6rem;
          position: absolute;
          bottom: 4px;
          right: 2px;

          input[type="file"] {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
            padding-left: 26px;
          }
        }
      }
    }
  
  .profile_label{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;  
    font-family: "Raleway_Medium",sans-serif;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-top: 12px;
  }
  &.errPop {
    max-width: 40.9rem;

    & > button{
      display: none;
    }
    // .custom_modal {
    //   padding-left: 4.2rem;
    //   padding-right: 4.2rem;
    // }
    h3{
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
    }
    p{
      @extend .fs_12;
    }
    .otpMsg{
      .form_row{
        .form_group{
          width: 41px;
          &:not(:last-child){
            margin-right: .9rem;
          }
          input{
            font-size: 40px;
            line-height: 42px;
            font-weight: 600;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
          }
        }
      }
    }
    .form_action{
      margin-bottom: 0;
    }
  }
  .form_heading {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    padding: 0 20px 0 80px;
    font-family: montserrat_semibold;
    font-weight: 500;
    @media (max-width: 768px) {
      &{
        padding: 0;
      }
    }
  }
}
.modal.errorPop{
  max-width: 400px !important;
}

.modal.errorPop {
.ant-modal-content{
  border-radius: 1rem ;
}
}

.modal.primaryPop{
  width: 553px !important;
text-align: center;
}

//.ant-modal-mask{
//  background: transparent !important;
//}
.ant-modal-close{
  top: 15px !important;
  right: 12px !important;
}
.ant-modal-close-x{
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
.ant-modal-body {
  padding: 34px 9px 24px 24px !important;
  @media (max-width: 768px){
    &{
      padding:  20px 9px 20px 20px!important;
    }
  }
}
.ant-modal{
  top: 0 !important;
}
.ant-modal-wrap {
  margin: 0 !important;
  padding: 20px !important;
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

// delete popup
.modal .forgot_psw_modal.main_comman_modal{
    max-width: 410px !important;
    width: 100% !important;
    padding: 22px 20px 28px !important;
   
    .form_group label{
      margin-bottom: 9px;
    }
    @media (max-height: 600px) {
      &{
        top: 50%;
        transform: translate(0, -50%) !important;
      }
    }
}
.modal-dialog.main_comman_modal.authenticate_modal {
  @include width(410px);
  padding: 30px 10px 37px;
  border-radius: 10px !important;
  .custom_modal {
    padding: 0;
  }
  .main_delete_modal{
    h4{
      margin: 0 !important;
      padding-bottom: 26px !important;
      font-family: montserrat_medium;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
    }
    .delete_content{
      padding-bottom: 12px !important;
      @include width(290px);
      margin: 0 auto;
      p{
        font-family: montserrat_medium;
        font-weight: 500;
      }
    }
  }
}
.main_delete_modal{
  max-width: 324px;
  width: 100% !important;
  margin: 0 auto;
  &.update-patient_modal{
    max-width: 410px !important;
    width: 100% !important;
    padding: 22px 20px 28px !important;
    border-radius: 10px !important;
    h4{
      margin-bottom: 0;
    }
    .delete_modal_btn{
      margin: 0 !important;
    }
  }
  h4{
    font-family: montserrat_medium;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 16px;
    margin: 0;
  }

  .delete_content{
    padding-bottom: 26px;
    p{
      @extend .montserrat_regular;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #495568;
      margin: 0;
      &:not(:last-child){
        padding-bottom: 10px;
      }
    }
  }
  .reversed, .proceed{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    display: block;
  }
  .proceed{
    padding: 32px 0 28px;
  }
  .delete_modal_btn{
    margin: 30px 0 0;
    .btn{
      margin: 5px 6px;
    }
  }
}
  .modal{
    .modal_btn{
      margin: 0;
      flex-wrap: wrap;
        button{
          min-width: unset;
          width: 46%;
          margin: 5px 6px;
        }
    }
}

// transfer patients popup
.transferPatient{
  max-width: 956px !important;
  width: 100% !important;
  .ant-modal-body{
    padding: 36px 20px !important;
    .main_transfer_patients{
      h4{
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282C;
        margin-bottom: 32px;
      }
      .transfer_form_grup{
        max-width: 438px;
        width: 100%;
        margin: 0 auto 28px;
      }
      .main_select_patient{
        max-width: 524px;
        width: 100%;
        margin: 0 auto;
        h6{
          @extend .montserrat_regular;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: #595959;
          padding: 0 0 10px 16px;
          margin: 0;
        }
        .select_patient_box{
          padding: 14px 0;
          background: #F4F4F4;
          border-radius: 3px;
          height: 328px;
          overflow: hidden;
          .select_all_patient_box{
            .main_select_all{
              padding: 0 18px;
            }
            .checkbox{
              span{
                width: 12px;
                height: 12px;
                background: #3E4A58;
                &::after{
                  content: "";
                  position: absolute;
                  display: none;
                }
              }
              label{
                @extend .montserrat_regular;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                margin-left: 8px;
              }
              & > [type="checkbox"]:checked + span:after{
                display: block;
                left: 3px;
                top: 0;
                width: 5px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: unset;
            }
            & > [type="checkbox"]:checked + span::before{
              display: none;
            }
            }
            .main_patients_details{
              display: flex;
              width: 100%;
              height: 230px;
              overflow-y: auto;
              .patients_details{
                width: 50%;
                h4{
                  @extend .montserrat_regular;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3125px;
                  text-transform: capitalize;
                  color: #000000;
                  font-weight: 600;
                  text-align: left;
                  margin: 0;
                }
                &.patients_name{
                  padding-right: 10px;
                  h4{
                    padding-left: 18px;
                  }
                  .inner_div{
                    display: flex;
                    align-items: baseline;
                    margin-top: 7px;
                    .form_group{
                      margin: 0;
                    }
                   span{
                     padding-left: 7px;
                   }
                  }
                }
                &.patients_email{
                  padding-left: 10px;
                  .show_name{
                    margin-top: 7px;
                  }
                }
                  .show_name{
                    span{
                     @extend .montserrat_regular;
                     font-weight: 600;
                     font-size: 13px;
                     line-height: 20px;
                     letter-spacing: 0.3125px;
                     color: #636363;
                    }
                  }
              }
            }
          }
        }
      }
      .modal_btn {
        max-width: 350px;
        width: 100%;
        margin: 50px auto 0;
        @media(max-width: 480px){
          max-width: 100%;
        }
    }
    }
  }
  .sub_transfer_patients{
    max-height: 70vh;
    overflow-y: auto;
  }
}

.form_group.search{
  max-width: 310px;
  width: 100%;
  margin: 0 auto 16px;
  padding: 0 10px;
  input{
    padding: 10px 33px;
    border-radius: 30px;
    color: #C4C4C4;
    letter-spacing: 0.8px;
    font-weight: 500;
    font-family: "montserrat_semibold",sans-serif;
    &::placeholder{
      letter-spacing: 0.8px;
      font-family: "montserrat_semibold",sans-serif;
    }
  }
  .search_icon{
    position: absolute;
    left: 16px;
    top: 46%;
    transform: translate(0, -50%);
  }
}
// minutePopup
.addMinutePop{
  .ant-modal-body {
    padding: 34px 20px 20px!important;
    }
  .custom_modal{
    .form{
      max-width: 365px;
      width: 100%;
      margin: 0 auto;
    }
    .form_group {
      margin-top: 30px;
      text-align: center;
      label{
        text-align: left;
      }
    }
  }
}
// AddAppoinment popup
.modal.addAppointmentPop{
  max-width: 956px !important;
  width: 100% !important;
  max-height: 100vh !important;
  background-color: unset;
  box-shadow: unset;
  .ant-modal-content {
    margin: 20px 0;
  }
  h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #25282C;
    @extend .montserrat_regular;
    margin-bottom: 35px;
  }
  .ant-modal-body{
    padding: 34px 9px 24px 24px !important;
  }
}
// authorizedPop
.sub_appoinment_block{
  max-height: 70vh;
  overflow-y: auto;
  .react-datepicker{
    font-size: 10px !important;
    font-family: "montserrat_medium",sans-serif;
  }
  .react-datepicker__current-month{
    font-size: 12px !important;
    font-family: "montserrat_medium",sans-serif;
  }
  .react-datepicker__day-name,.react-datepicker__day, .react-datepicker__time-name{
    width: 30px !important;
    @media (max-width: 680px) {
      width: 20px !important;
    }
  }
}
.addappoinment_block{
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  .date_block{
    position: relative;
    z-index: 99;
    &::after{
      content: "";
      background: url(../images/calendar-icon.png) no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      display: block;
      top: 50%;
      position: absolute;
      right: 7px;
      transform: translate(0, -50%);
      cursor: pointer;
      z-index: 1;
    }
  }
}
.appoinment_time{
  max-width: 330px;
  width: 100%;
  margin-bottom: 27px;
  .form_group{
    margin: 0;
    width: 50%;
    input{
      border: none;
      background: rgba(229, 229, 229, 0.5);
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #3E4A58;
      padding: 10px 24px 10px 10px;
      &::placeholder{
        font-weight: 500;
        font-family: montserrat_medium;
      }
    }
    &:first-child{
      padding-right: 10px;
      margin: 0;
    }
    &:nth-child(2){
      padding-left: 10px;
      margin: 0;
    }
    span{
      display: inline-block;
    }
    .time_block{
      display: flex;
      width: 100%;
      align-items: center;
      p{
        @extend .montserrat_regular;
        margin: 0;
        padding-left: 6px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #3E4A58;
      }
      .pst{
        font-family: montserrat_semibold;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #3E4A58;
        padding-left: 6px;
      }
    }
    @media (max-width: 600px) {
      &{
        &:first-child{
          padding: 0 0 15px;
        }
        &:nth-child(2){
          padding-left: 0;
        }
      }
    }
  }
}
.main_call_length{
  margin-bottom: 28px;
  p{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #495568;
    padding-bottom: 4px;
    margin: 0;
  }
}
.radio_buttons_block{
  display: flex;
  align-items: center;
  .min_block{
      @extend .montserrat_regular;
      display: block;
      cursor: pointer;
      position: relative;
    &:first-child{
      padding-right: 16px;
      padding-left: 25px;
    }
    &:nth-child(2){
      padding-left: 25px;
    }
    label{
      font-family: montserrat_medium;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-transform: lowercase;
      color: #3E4A58;
    }
    input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 16px;
      width: 16px;
      z-index: 99;
    }
    .check {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 16px;
      width: 16px;
      background-color: #457B9D;
      border-radius: 100%;
      &::after{
        content: "";
        position: absolute;
        display: none;
      }
    }
  input:checked ~ .check{
      background-color: #457B9D;
    }
    input:checked ~ .check::after{
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
.main_note_block{
  margin-bottom: 32px;
  textarea{
    background: rgba(229, 229, 229, 0.5);
    border-radius: 10px;
    border: none;
    resize: none;
    height: 80px;
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #7C7C7C;
    padding: 8px;
    &::placeholder{
      font-size: 12px;
      line-height: 15px;
      color: #7C7C7C;
    }
  }
  span{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    display: block;
  }
}
.select_patient_block{
  h6{
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #495568;
    margin-bottom: 14px;
  }
  .current_patient_box{
    height: 400px;
    overflow: hidden;
    .current_patient_header{
      @include flex;
      flex-wrap: wrap;
      padding: 5px 27px 5px 7px;
      background-color: #457B9D;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @media (max-width: 991px){
        padding-right: 10px;
      }
      .form_group.search{
        max-width: 200px;
        margin: 0;
        padding: 5px;
        input {
          padding: 5px 20px 5px 33px !important;
          border-radius: 30px !important;
          min-height: unset !important;
          background: #FFFFFF;
          border: 1.5px solid #DEDEDE;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: #3e4a58;
          font-weight: 500;
          @extend .montserrat_medium;
          &::placeholder{
            @extend .montserrat_medium; 
          }
        }
      }
      h6{
        @extend .montserrat_regular;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.9px;
        color: #FFFFFF;
        padding: 5px;
      }
    }
    .main_cp_list{
        height: 342px;
        overflow-y: auto;
        background: rgba(229, 229, 229, 0.5);
        .main_cp_list_col{
          padding: 7px 20px 9px 10px;
          background-color: #DADADA;
          filter: drop-shadow(4px 2px 12px rgba(0, 0, 0, 0.1));
          @include flex;
          flex-wrap: wrap;
          @media (max-width: 991px){
            padding-right: 10px;
          }
          &:nth-child(2n){
            background-color: #F8F8F8;
          }
          .cp_profile_block {
            display: flex;
            width: 50%;
            padding: 5px 10px;
            .profile {
              max-width: 24px;
              width: 100%;
              height: 24px;
              img{
                @include image;
                height: 100%;
                object-fit: cover;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
                border-radius: 100%;
              }
            }
          span{
            @extend .montserrat_regular;
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.9px;
            color: #2D2D2D;
            padding-left: 8px;
          }
          }
          .cp_checkbox{
            padding: 5px 10px;
            .checkbox{
              margin: 0;
              [type=checkbox] {
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    cursor: pointer;
                }
              span{
                width: 20px;
                height: 20px;
                border: 2px solid #3E4A58;
                border-radius: 100%;
                &::after{
                  content: "";
                  position: absolute;
                  display: block;
                  top: 44%;
                  width: 6px;
                  height: 10px;
                  left: 50%;
            
                  transform: translate(-50%, -50%) rotate(
              45deg);
                  background-color: unset;
                }
              }
              & > [type="checkbox"]:checked + span{
                background: #3E4A58;
              }
              & > [type="checkbox"]:checked + span:after{
                border: solid white;
                background-color: unset;
                border-width: 0 2px 2px 0 !important;
            }
            & > [type="checkbox"]:checked + span::before{
              display: none;
            }
            }
          }
        }
        .srv-validation-message{
          padding: 10px;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #BFC6CF;
        }
        &::-webkit-scrollbar-thumb {
          background: #3E4A58;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #C4C4C4 ;
        }
    }
  }
}
.appoinment_btn{
  max-width: 324px;
  width: 100%;
  margin: 40px auto 0 !important;
  @media (max-width: 991px) {
      margin: 20px auto 0;
      max-width: 100%;
  }
  .btn{
    margin: 5px 7px;
  }
}
.ant-modal.modal.authorizedPop{
  max-width: 956px !important;
  width: 100% !important;
  .custom_modal{
    padding-bottom: 29px;
  }
}
.edit_form_group{
  max-width: 520px !important;
  .text_add {
    align-items: center;
    input{
      max-width: 434px;
      width: 100%;
    }
    span{
      position: unset;
      transform: unset;
      width: calc(100% - 434px);
      padding-left: 10px;
    }
    @media (max-width: 750px) {
        &{
          flex-wrap: wrap;
          input{
            max-width: 434px;
          }
          span{
            width: 100%;
            padding-top: 8px;
          }
        }
    }
  }
}
// delete patients modal
.main_comman_modal.deleteModal{
  max-width: 523px !important;
  width: 100% !important;
  padding: 30px 20px 30px !important;
  .form_action{
    margin-top: 36px;
  }
  .content{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #495568;
    font-family: montserrat_medium;
    @include width(370px);
    margin: 25px auto 0;
  }
  &.deleteModal_main{
    max-width: 380px !important;
    .custom_modal{
      padding: 0;
    }
  }
  &.deletePop{
    max-width: 410px !important;
    width: 100% !important;
    padding: 27px 20px 28px !important;
    .ant-modal-body{
      padding: 22px 20px 28px !important;
    }
    .custom_modal{
      padding: 0;
    }
  }
}

// Add Patients modal
.main_comman_modal.addPatientModal{
  padding: 35px 20px 42px !important;
  .custom_modal{
    padding: 0;
    .form_heading{
      margin-bottom: 28px;
    }
  }
}
// sweet alert 
.swal2-popup.swal2-modal {
  // max-width: 400px!important;
  @include width(400px !important);
  width: 100%;
  padding: 10px 24px 30px 24px!important;
  .swal2-title {
      font-size: 16px;
      font-weight: 600;
      font-family: montserrat_regular;
      line-height: 25px;
  }
  .swal2-html-container {
      color: #494949;
      line-height: 1.7rem;
      margin-bottom: 20px;
      text-align: center;
      font-family: montserrat_regular;
      @extend .ls_03;
      @extend .fs_14;
  }
  button.swal2-confirm {
      background: #c00c2d;
      color: #fff;
      line-height: 2rem;
      min-width: 15.4rem;
      padding: 1.1rem 2.5rem;
      transition: all .3s ease-in-out;
      @extend .fs_14;
      @extend .fw_600;
      @extend .ls_03;
      @extend .radius_10;
      @extend .cursor_pointer;
  }
  .swal2-styled.swal2-confirm:focus {
      box-shadow: none !important;
  } 
}
.nodata {
  padding: 14px;
  text-align: center;
}
.request_min_block{
  display: flex;
  align-items: center;
  padding: 0;
  white-space: nowrap;
  flex-wrap: nowrap;

  .radio_buttons_block{
    padding: 5px 30px 5px 0;
  }
  .req_min_btn{
    padding: 5px 0;
    .btn{
      font-family: montserrat_medium;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      min-width: unset;
      padding: 5px 20px;
    }
  }
  &.min_block{
    justify-content: flex-start;
  }
  @media (max-width: 991px) {
    padding-right: 10px;
    flex-wrap: wrap;
    &.min_block{
      justify-content: flex-start;
    }
    .radio_buttons_block, .available_min_block{
      padding-right: 10px;
    }
  }
}
.available_min_block{
  padding: 5px 15px 5px 0;
  span{
    font-family: montserrat_medium;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3E4A58;
  }
}
.modal-dialog.main_comman_modal.appoinment-modal_block {
  padding: 32px 20px 15px;
  h4{
    font-family: montserrat_semibold;
    font-weight: 500;
  }
}

.otpMsg {
  p.notice{
    font-family: montserrat_semibold;
    padding: 15px 0 0;
  }
  .otp_error-msg{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #C00C2D;
    font-family: raleway_medium;
    padding-top: 12px;
    @include width(230px);
    margin: 0 auto;
  }
  p.notice, p.resend_code_msg{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #495568;
    max-width: 270px;
    width: 100%;
    margin: 0 auto 21px;
  }
  .form_row{
    justify-content: center;
    @media (max-width: 600px){
      & {
        flex-direction: unset;
        .verification_otp{
          margin-bottom: 20px;
        }
      }
    }
    .ReactInputVerificationCode__container {
      max-width: 240px;
      width: 100%;
      margin: 0 auto 26px;
  }
    .ReactInputVerificationCode__item {
      max-width: 40px;
      width: 100%;
      height: 58px;
      background: #E5E5E5;
      border-radius: 5px;
      box-shadow: none !important;
      padding: 5px;
      margin: 4px;
      font-weight: 600;
      font-size: 40px;
      line-height: 42px;
      color: #000000;
      @extend .montserrat_bold;
      @include flex;
  }
}
p.resendcode_msg{
  font-family: montserrat_medium;
  margin: 0 !important;
  max-width: unset;
  text-align: center;
  color: #495568;
}
}
.resendcode_btn{
  margin: 26px 0 0;
}
.main_comman_modal.deleteModal.leave_chat-modal{
  max-width: 400px !important;
  .custom_modal{
    padding: 0;
  }
  .form_action{
    margin-top: 20px;
  }
}

// .request-minutes{
//   .min_block{
//     .check{
//      background-color: #CBCBCB;
//    }
//    label{
//     color: #CBCBCB;
//    }
//   }
// }