//.modal-error{
//  position: relative;
//  .modal-overly{
//    position: fixed;
//    z-index: 100;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-color: rgba(0,0,0,0.4);
//    transition: 3s;
//    .modal_body{
//      z-index: 8;
//      border-radius: 1rem;
//      background: whitesmoke;
//      text-align: center;
//      width: 400px;
//      position: absolute;
//      left: 50%;
//      top: 50%;
//      transform: translate(-50%, -50%);
//      //max-width: calc(100vw - 32px);
//      padding: 3rem;
//      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
//      .close-btn{
//        position: absolute;
//        right: 15px;
//        top: 10px;
//        transition: 3s;
//        img{
//          cursor: pointer;
//        }
//      }
//      .attached_pop{
//        h3{
//          font-size: 1.8rem;
//          padding-bottom: 20px;
//          color: black;
//          text-align: center;
//        }
//      }
//    }
//  }
//}

.delete-pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    margin: 15px;
    font-size: 22px;
  }
  .desc {
    color: #494949;
    line-height: 1.7rem;
    text-align: center;
    margin-bottom: 20px;
  }
  .action {
    @extend .d_flex;
    @extend .justify_content_between;
    @extend .align_items_center;
    .btn_primary {
      background: #C00C2D;

    }
  }
}
.modal.editClinic{
  width: 78.6rem !important;
  top: 58px;
}
.edit-clinic-modal{
  width: calc(100% - 0rem);
  .clinic_form{
    max-width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    @include scrollbar;
  }
}
.filter-drop-status{
      position: absolute;
      min-width: 18.4rem;
      background: #ffffff;
      padding: 2.5rem 2rem;
      box-shadow: 0 0 2.5rem rgb(0 0 0 / 15%);
      z-index: 9;
}
.pms_patients_table{
  .filter-drop-status{
    left: 0;
    right: 0;
    top: 90%;
    margin: 0 auto;
    label{
      width: auto;
    }
  }
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  content: " " !important;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #fff;
   border-color: #d9d9d9 !important;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover{
  box-shadow: none !important;
border-right-width: 0px !important;
  outline: 0;
}

// delete appoiment popup
.modal-dialog.delete_appoinment_modal{
  max-width: 400px !important;
  padding: 30px 20px !important;
  .delete_modal_btn{
    margin: 20px 0 0;
    .btn{
      margin: 5px;
    }
  }
}
.caregiver_font_style{
  font-size: 12.5px;
  line-height: 15px;
}

.caregiversPop{
  max-width: 414px !important;
  width: 100% !important;
  .ant-modal-body{
    padding: 30px 10px 46px !important;
  }
  .caregivers-main_block{
    h5{
      font-family: montserrat_semibold;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      padding-bottom: 19px;
    }
    .caregivers-sub_block{
      min-height: 20vh;
      max-height: 40vh;
      overflow-y: auto;
      padding: 0 22px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #BFC6CF;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    .caregivers-detail_block{
      padding: 22px 15px 20px;
      background: #F2F2F2;
      border-radius: 10px;
      display: flex;
      align-items: flex-start;
      &:not(:last-child){
        margin-bottom: 7px;
      }


      .profile_pic{
        @include width(50px);
        height: 50px;
        img{

          border-radius: 100%;
          @include image;
          height: 50px;
          width: 50px;
          object-fit: cover;
        }
      }
      .caregivers_detail{
        width: calc(100% - 50px);
        padding-left: 12px;
        h6{
          font-family: raleway_bold;
          font-weight: 600;
          @extend .caregiver_font_style;
          color: #000000;
          padding-bottom: 1px;
        }
        .comman_detail{
          font-family: raleway_medium;
          font-weight: 500;
          @extend .caregiver_font_style;
          display: inline-block;
          width: 100%;
          &:not(:last-child){
            padding-bottom: 2px;
          }
          img{
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: 8px;
          }
          &.user_detail{
            img{
              width: 18px;
              height: 12px;
            }
          }
          &.mail{
            img{
              width: 15px;
              height: 12px;
            }
          }
        }
      }
      .checkbox_block{
        align-self: center;
        position: relative;
        display: block;
      }
      .checkbox_clone{
        width: 20px;
        height: 20px;
        border: 2px solid #457B9D;
        border-radius: 100%;
        position: relative;
        z-index: 1;
        background: #457B9D;
        display: none;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 44%;
          width: 6px;
          height: 10px;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border: solid white;
          background-color: unset;
          border-width: 0 2px 2px 0 !important;
        }
      }
      @media (max-width: 500px) {
        &{
          flex-direction: column;
          padding: 15px;
          .profile_pic{
            margin-bottom: 7px;
          }
          .caregivers_detail{
            padding-left: 0;
            width: 100%;
          }
          .checkbox_block{
            margin: 5px auto 0 0;
          }
        }
      }
      &.selected_caregiver{
        background: rgba(69, 123, 157, 0.17) !important;
        .checkbox_clone{
          display: inline-block;
        }
      }
    }
  }
}

.caregivers-sub_block{
  position: relative;
  .data_load{
    position: absolute;
    left: 47%;
    top: 40%;
  }
}