.main-videoscreen_block{
        @include width(600px);
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        .sub-videoscreen_block{
                height: 100%;
                position: relative;
                background: linear-gradient(0deg, #495568, #495568), #C4C4C4;
                display: block;
                video{
                   object-fit: cover;
                   object-position: center;
                   position: absolute;
                   top: 0;
                   left: 0;
                   height: 100%;
                   width: 100%;
                   transform: scale(-1, 1);
                }
                &:first-child {
                        width: 100%;
                }
                &:nth-child(3) {
                        height: 50%;
                }
                .participant_name{
                        position: absolute;
                        bottom: 12px;
                        left: 15px;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 13px;
                        text-align: center;
                        text-transform: capitalize;
                        color: #FFFFFF;
                        background: #606060;
                        border-radius: 10px;
                        padding: 5px 9px;
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                      
                }
                .mute_icon-block{
                        position: absolute;
                        bottom: 12px;
                        right: 4px;
                        img{
                                width: 20px;
                                height: 20px;
                                display: block;
                                margin-right: 5px;
                            }
                }
             
        }
        .half{
                width: 50% !important;
                height: 50%;
               border-bottom: 1px solid #000;
          &:nth-child(2){
            border-right: 1px solid #000;
          }
          &:nth-child(3){
            border-left: 1px solid #000;
          }
          &:first-child {
            border-right: 1px solid #000;
          }
        }
        .full{
                width: 100%;
                height: 50%;
          &:first-child {
            border-bottom: 1px solid #000;
          }
        }
}
.videoscreen_header{
        background: #457B9D;
        .logo{
                max-width: 40px;
                margin: 0 auto;
                img{
                        @include image;
                }
        }
}
.videoscreen_btn {
        position: fixed;
        bottom: 0;
        background: #457B9D;
        width: 100%;
        padding: 16px 10px;
        display: block;
        text-align: center;
        button{
                display: inline-block;
                vertical-align: middle;
                border: none;
                background-color: unset;
        }
        .main_vsbutton-block{
                @include width(600px);
                display: flex;
                padding: 0 20px;
                margin: 0 auto;
                .vs-button_block{
                    width: 60%;
                    display: flex;
                    justify-content: flex-end;
                }
                .leave-btn{
                        width: 40%;
                        display: block;
                        text-align: right;
                        button{
                                font-family: raleway_bold;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 14px;
                                color: #457B9D;
                                padding: 8px 10px;
                                @include width(85px);
                                background: #FFFFFF;
                                border-radius: 30px;
                        }
                }
        }
  &.vd_screen_footer{
    height: 63px;
  }
}
.videoscreen_container {
        height: calc(100vh - 109px);
        background-color: #3E4A58;
}
.audio_icon{
            @include width(28px);
            height: 28px;
            margin: 0 5px;
        img{
            width: 100%;
            height: 100%;
            display: block;
        }
        &.phone_icon{
                img{
                    filter: brightness(0) invert(1);
                }
        }
}
p.pc_name {
     color: #fff;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     font-size: 14px;
     font-weight: 600;
     line-height: 16px;
     text-align: center;
     max-width: 140px;
     width: 100%;
}