.chat_profile{
    // max-width: 40rem;
    padding: 4px 0 3px;
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_end;
    line-height: 0;
    .profile{
        @extend .d_flex;
        @extend .align_items_center;
        &_name{
            @extend .fs_14;
            line-height: 2rem;
            color: $black;
            font-family: montserrat_semibold;
            padding-left: 10px;
            display: inline-block;
        }
        &_pic{
            width: 45px;
            height: 45px;
            border: 2px solid #ffffff;
            filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25));
            border-radius: 100%;
            img{
                width: 100%;
                max-width: 100%;
                display: block;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }
    
}   
.chat_area {
    height: auto;
    .chat_area_wrap {
        height: calc(100% - 75px);
        .chat_msg_list_wrap {
            height: 100%;
            @extend .d_flex;
            flex: 1 1 auto;
            flex-direction: column;
            min-height: 1px;
            .chat_msg_container {
                height: 100%;
                overflow: hidden;
                overflow-y: scroll;
                max-width: 1330px;
                @extend .mx_auto;
                @extend .w_100;
                padding: 0 20px;
                position: relative;
                @include comman_scrollbar_firefox(#BFC6CF, transparent);
                .chat_msg_list {
                    padding: 20px 30px 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    // overflow: auto;
                    height: 100%;
                    .message_section {
                        @extend .d_flex;
                        margin: 15px 0;
                        &.message_left {
                            @extend .justify_content_start;
                        }
                        &.message_right {
                            @extend .justify_content_end;
                            .msg_avtar {
                                @extend .ml_2;
                            }
                        }
                        .msg_avtar {
                            @extend .mr_2;
                            .avtar_img {
                                width: 45px;
                                height: 45px;
                                border: 1px solid #ffffff;
                                border-radius: 100%;
                                filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25));
                                -webkit-filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25));
                                @extend .radius_rounded;
                                img {
                                    @include image;
                                    max-height: 100%;
                                    height: 100%;
                                    @extend .img_object_center;
                                    border-radius: 100%;
                                }
                            }
                        }
                        .messages {
                            background: #f5f7fb;
                            padding: 10px;
                            border-radius: 0 5px 5px 5px;
                            min-width: 60px;
                            max-width: 75%;
                            .user_message {
                                font-size: 13px;
                                font-family: raleway_bold;
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                p{
                                    line-height: 1.5;
                                    span.red {
                                        color: #C00C2D;
                                    }
                                    &.user_title{
                                        padding-right: 20px;
                                    }
                                }
                            }
                            .message_content {
                                padding: 10px 0 0;
                                font-family: montserrat_medium;
                                font-size: 13px;
                            }
                        }
                    }
                }
                &::-webkit-scrollbar{
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb{
                    background: #BFC6CF;
                }
                &::-webkit-scrollbar-track{
                    background: transparent;
                }
            }
            .chat_input_wrap {
                max-width: 1390px;
                @extend .w_100;
                padding: 10px 30px;
                margin: auto;
                position: relative;
                background: #fff;
                .chat_input_block {
                    border: 1px solid #dddfe8;
                    background: #fff;
                    position: relative;
                    bottom: 0;
                    @extend .w_100;
                    min-height: 50px;
                    @extend .d_flex;
                    @extend .align_items_end;
                    .chat_editor {
                        flex: 1 1;
                        .msg_text {
                            input{
                                color: #333;
                                font-family: montserrat_medium;
                                height: 50px;
                                @extend .w_100;
                                padding: 15px;
                                border: 0;
                                font-weight: 600;
                                outline: none;
                                &::placeholder{
                                    color: #73788b;
                                    font-family: montserrat_medium;
                                }
                            }
                        }
                    }
                }
                .message_action {
                    min-height: 50px;
                    @extend .d_flex;
                    @extend .align_items_center;
                    .send-message {
                        border-radius: 30px;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        text-transform: uppercase;
                        @extend .w_100;
                        @extend .d_block;
                        padding: 10px !important;
                        margin: 0 15px 0 0;
                        @extend .cursor_pointer;
                    }
                }
            }
        }
    }
    .chat_msg_list::-webkit-scrollbar {
        display: none;
    }
}
.clinics.msg-list_block{
    height: auto;
}