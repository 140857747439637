// Table
.table_responsive {
  overflow-x: auto;
  min-height: 150px;
  // min-height: 20rem;
  .table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    thead {
      background: rgba($purple, .37);
      width: 100%;
      @extend .text_capitalize;
      tr {
        td {
          @extend .fs_14;
          line-height: 2rem;
          color: $black;
          @extend .text_capitalize;
          @extend .montserrat_semibold;
          // @extend .white_space_nowrap;
          padding: 1.15rem 1rem; 
          .arrow_up{
            width: 1.6rem;
            @extend .cursor_pointer;
            // @extend .ml_05;
              img{
                  width: .8rem;
                  @extend .img_object_center;
              }
          }
          @media (max-width: 1500px){
            font-size: 1.2rem;
          }
        }
      }
    }
    tbody{
        tr{
            td{
                @extend .fs_13;
                line-height: 2rem;
                color: $text_think;
                @extend .text_capitalize;
                font-family: 'montserrat_medium';
                padding: 26px 10px 18px; 
                border-bottom: 1px solid #ddd;
                // height: 75px;
                // min-height: 75px;    
                text-transform: unset;                           
                a{
                    color: $text_blue;
                }
                .actions_icon{
                    // width: 1.4rem; 
                    @extend .d_flex_center;
                    @extend .cursor_pointer;
                    img{
                      width: 14px;
                      height: 16px;
                        // @extend .img_object_center;
                    }
                    &:last-child{
                      margin: 0 !important;
                    }
                }
                &.td_emailphone{
                  padding-bottom: 5px;
                   // padding-top: 20px;
                   span{
                    display: block;
                    &:empty{
                      padding: 8px;
                    }
                  }
                }
                &.td_icons{
                  padding-right: 30px;
                  @media (max-width: 1679px){
                    padding-right: 20px;
                  }
                }
            }
        }
    }
    &.clinic_data_list {
      min-width: 110rem;
      table-layout: fixed;
      margin-bottom: 0;
      td {
        &:nth-child(1) {
          width: 3%;
          padding-right: 0px;
          img{
            display: inline-block;
            vertical-align: top;
            max-width: 20px;
            margin: 0 auto;
          }
        }
        &:nth-child(2), &:nth-child(3) {
          width: 16%;
        }
        &:nth-child(4) {
          width: 18%;
          @media (max-width: 1679px){
            width: 23%;
          }
        }
        &:nth-child(5), &:nth-child(6) {
          width: 8%;
          @media (max-width: 1679px){
            width: 9%;
          }
        }
        &:nth-child(7) {
          width: 10%;
        }
        &:nth-child(8) {
          width: 11%;
        }
        &:nth-child(9){
          width: 8%;
          padding-right: 26px;
          text-align: right;
          @media (max-width: 1679px){
            width: 10%;
            padding-right: 10px;
          }
          @media (max-width: 1500px){
            width: 12%;
            padding-right: 10px;
          }
        }
        &:nth-child(5){
          text-align: center;
        }
      }
      &.physician{
        td{
          &:nth-child(2){
            width: 18%;
          }
          &:nth-child(3) {
            width: 18%;
            @media (max-width: 1679px) {
                width: 25%;
            }
          }
          &:nth-child(4){
            width: 15%;
            @media (max-width: 1679px){
              width: 21%;
            }
          }
          &:nth-child(6) {
            width: 12%;
          }
          &:nth-child(5) {
            width: 12%;
          }
          &:nth-child(7){
            width: 13%;
            @media (max-width: 1679px) {
              width: 20%;
            }
            @media (max-width: 1500px) {
              width: 17%;
            }
          }
          &:nth-child(8){
            width: 8%;
            padding-right: 26px;
            text-align: right;
            @media (max-width: 1679px){
              width: 12%;
              padding-right: 10px;
            }
          }
        }
      }
      &.pms_patients_table{
        .invite_div{
          text-align: center;
        }
        thead{
          td{
            vertical-align: top;
          }
        }
        td{
          width: 150px;
          min-width: 150px;
          &:nth-child(1) {
            width: 18px;
            min-width: 18px;
          }
          &:nth-child(2){
            // width: 12%;
            width: 110px;
            min-width: 110px;
          }
          &:nth-child(3){
            // width: 13%;
            width: 155px;
            min-width: 155px;
            // @media (max-width: 1679px) {
            //   width: 170px;
            //   min-width: 170px;
            // }
            @media (max-width: 1500px) {
              width: 190px;
              min-width: 190px;
            }
          }
          &:nth-child(4){
            // width: 5%;
            // @media (max-width: 1600px) {
            //   width: 3%;
            // }
            width: 70px;
            min-width: 70px;
            text-align: center;
          }
          &:nth-child(5){
            // width: 7%;
            // text-align: left;
            text-align: center;
            width: 50px;
            min-width: 50px;
            .cal_img_block{
              margin: 0 auto;
            }
          }
          &:nth-child(6),  &:nth-child(7){
            width: 80px;
            min-width: 80px;
            text-align: center;
          }
          &:nth-child(6){
            .td_status_main{
              display: block;
            }
          }
          &:nth-child(8){
            // width: 7%;
            // @media (max-width: 1679px) {
            //   width: 8%;
            // }
            width: 80px;
            min-width: 80px;
          }
          &:nth-child(9){
            width: 80px;
            min-width: 80px;
          }
          &:nth-child(10){
            // width: 6%;
            width: 80px;
            min-width: 80px;
            text-align: right;
            padding-right: 30px;
            @media (max-width: 1500px) {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}

.msg_table thead tr td:last-child, .msg_table tbody tr td:last-child {
  text-align: right !important;
  padding-right: 50px !important;
  width: 22% !important;
}
.css-1meh2x2-BaseToolTop{
  .css-17oi9u9-Bubble{
    color: #fff;
    background: #000;
    border: 1px solid #000;
    padding: 7px;
    font-size: 12px;
    width: auto !important;
    min-width: 100px;
    max-width: 100px;
    font-family: 'montserrat_regular' !important;
    text-align: center;
    border-radius: 5px;
  }
}
.css-2zis3v {
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
  border-radius: 9px !important;
  padding: 8px !important;
}
.video-pop{
  max-width: 426px !important;
  width: 100% !important;
  padding: 30px 20px 2px !important;
  .form_action {
    margin-top: 3rem;
  }
}
.hide_message_error{
  display: none;
}